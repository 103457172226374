import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/sales-transactions';
import Datatable from '../common/datatable';
import VerOrderDatatable from '../common/ver-order-delivery-datatable';
import VerDetailsOrderDatatable from '../common/ver-details-order-datatable';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Modal from 'react-responsive-modal';
import Moment from 'moment';

export class VerOrderDeliveryList extends Component {
  constructor(props) {
    super(props);
    this.handlerReady = this.handlerReady.bind(this);
    this.handlerDelivered = this.handlerDelivered.bind(this);
    this.handlerDetails = this.handlerDetails.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.state = {
      open: false,
      dataCateg: [],
      results: [],
      detailResults: [],
      client: '',
      date: '',
      hour: '',
      id_order: null,
      total_order: null,
      address: '',
      district: '',
      interior: '',
      url_google: '',
      cash: 0,
      delivery: 0,
      entregados: 0,
      totalAmount: 0,
      temp: [],
    };
  }

  componentWillMount() {
    this.getDataOrders();
  }

  onChangeFilter(e) {
    this.setState(
      {
        filter: e.target.value,
      },
      function () {
        console.log('');
      }
    );

    const val = e.target.value.toLowerCase();
    //console.log(event);
    if (this.state.temp.length != 0) {
      var columns = Object.keys(this.state.temp[0]);
      //Los botones de acciones se pintan despues
      columns.splice(columns.length);
      //columns.splice(columns.length - 1);

      if (!columns.length) return;
      const rows = this.state.temp.filter(function (d) {
        for (let i = 0; i <= columns.length; i++) {
          let column = columns[i];
          if (
            d[column] &&
            d[column].toString().toLowerCase().indexOf(val) > -1
          ) {
            return true;
          }
        }
      });
      //console.log('rows',rows)
      this.setState(
        {
          results: rows,
        },
        function () {
          //console.log('results',this.state.results);
          //console.log('lenght',this.state.results.length)
          this.renderResults(this.state.results.length);
        }
      );
      /*this.Inventarios = rows;
          let articulosAlmacenes;
          articulosAlmacenes = { 'Articulos': this.Inventarios }
          this.buildpurchaseForm(articulosAlmacenes);*/
    }

    e.preventDefault();
  }

  getDataOrders() {
    var user = AuthService.getCurrentUser();
    ApiService.getOrdersToday(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('orders',res.data)
          var countentregados = 0;
          const items = res.data.map(function (row) {
            if (row.status_delivery == 'ENTREGADO') {
              countentregados++;
            }
            var start_time = row.hour_programmed_start
              ? row.hour_programmed_start.toString()
              : '';
            var end_time = row.hour_programmed_end
              ? row.hour_programmed_end.toString()
              : '';

            var totalOrder = row.totalOrder;

            return {
              Fecha: row.date_programmed,
              Hora: 
                start_time && end_time 
                ? start_time.slice(0, -3) + ' - ' + end_time.slice(0, -3) 
                : '',
              Id: row.id,
              id_user: row.id_user,
              Usuario: row.User.name + ' ' + row.User.last_name,
              Embajador: row.Referred
                ? row.Referred.name + ' ' + row.Referred.last_name
                : '',
              Teléfono: row.phoneAmbassador,
              'Estado Entrega': row.status_delivery,
              //address: row.address,
              Delivery: row.delivery ? row.delivery : '0.00',
              Total: totalOrder,
              data: row,
            };
          });

          this.setState({
            results: items,
            entregados: countentregados,
            temp: items,
            totalAmount: res.totalAmount.total,
          });

          this.renderResults();
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  renderResults() {
    return (
      <>
        <div className="collection_data_container row justify-content-center">
  <div className="col-lg-3 col-12 orders-today-container">
    <label>HOY</label>
    <br />
    <label>({Moment(new Date()).format('DD/MM/YYYY')})</label>
  </div>

  <div className="col-lg-3 col-12 total-order-container">
    <div>
      <label>TOTAL</label>
      <label>{this.state.totalAmount}</label>
    </div>
  </div>

  <div className="col-lg-3 col-12 total-order-container">
    <div>
      <label>TOTAL DE PEDIDOS</label>
      <label>{this.state.results.length}</label>
    </div>
  </div>

  <div className="col-lg-3 col-12 total-order-container total-delivered-container">
    <div>
      <label>ENTREGADOS</label>
      <label>{this.state.entregados}</label>
    </div>
  </div>
</div>

        <div className="col-md-6 table-filter">
          <input
            placeholder="Escriba para filtrar todas las columnas"
            name="filter"
            type="text"
            className="form-control  table-column-filter-input"
            autoComplete="off"
            onInput={this.onChangeFilter}
          />
        </div>
        <div id="batchDelete" className="transactions">
          {this.state.results.length === 0 ? (
            ''
          ) : (
            <VerOrderDatatable
              multiSelectOption={false}
              myData={this.state.results}
              pageSize={
                this.state.results.length <= 100 ? this.state.results.length : 100
              }
              pagination={true}
              class="-striped -highlight"
              handlerReady={this.handlerReady}
              handlerDelivered={this.handlerDelivered}
              handlerDetails={this.handlerDetails}
            />
          )}
        </div>
      </>
    );
  }

  renderDetailResults() {
    return this.state.detailResults.length === 0 ? (
      ''
    ) : (
      <VerDetailsOrderDatatable
        multiSelectOption={false}
        myData={this.state.detailResults}
        pageSize={this.state.detailResults.length + 1}
        pagination={true}
        class="-striped -highlight"
      />
    );
  }

  showImage(results) {
    // Aplicamos un fix si no devuelve una imagen
    let url_image = `${process.env.REACT_APP_URL_FILES}/images/products/`;
    for (let i = 0; i < results.length; i++) {
      results[i]['imagen'] = (
        <img
          src={
            url_image +
            (results[i]['Product']['url']
              ? results[i]['Product']['url']
              : results[i]['Product']['logo']
              ? results[i]['Product']['logo']
              : '')
          }
          style={{ width: 50, height: 50 }}
        />
      );
    }
    return results;
  }

  handlerDetails(data_row) {
    //console.log('data_row',data_row);
    var user = AuthService.getCurrentUser();
    ApiService.getOrderDetails(user.token, data_row.original.Id).then(
      (res) => {
        if (res && res.status == 'success') {
          const items = this.showImage(res.data.Purchases_detail).map(function (
            row
          ) {
            return {
              id: row.id,
              Imagen: row.imagen,
              Nombre: row.Product.name,
              Precio: row.price,
              Cantidad: row.quantity,
              Total: row.total,
            };
          });

          var type_address = data_row.original.data.type_address
            ? data_row.original.data.type_address
            : '';
          var address = data_row.original.data.address
            ? data_row.original.data.address
            : '';
          var number = data_row.original.data.number
            ? data_row.original.data.number
            : '';
          var start_time = res.data.hour_programmed_start
            ? res.data.hour_programmed_start.toString()
            : '';
          var end_time = res.data.hour_programmed_end
            ? res.data.hour_programmed_end.toString()
            : '';

          this.setState({ detailResults: items });
          this.setState({ client: data_row.original.Usuario });
          this.setState({
            date: res.data.date_programmed ? res.data.date_programmed : '',
          });
          this.setState({
            hour:
              start_time && end_time
                ? start_time.slice(0, -3) + ' - ' + end_time.slice(0, -3)
                : '',
          });
          this.setState({ id_order: data_row.original.Id });
          this.setState({ total_order: data_row.original.Total });
          this.setState({
            address: type_address + ' ' + address + ' ' + number,
          });
          this.setState({
            cash: res.data.cash ? parseInt(res.data.cash, 10) : 0,
          });
          this.setState({
            district: data_row.original.data.district
              ? data_row.original.data.district
              : '',
          });
          this.setState({
            interior: data_row.original.data.interior
              ? data_row.original.data.interior
              : '',
          });
          this.setState({
            url_google: data_row.original.data.url_google
              ? data_row.original.data.url_google
              : '',
          });
          this.setState({
            delivery: res.data.delivery ? res.data.delivery : '0.00',
          });

          this.renderDetailResults();
          this.onOpenModal();
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  handlerReady(data_row) {
    Swal.fire({
      title: 'Confirmar',
      text:
        '¿La orden nro ' + data_row.original.Id + ' está listo para entregar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        var user = AuthService.getCurrentUser();
        console.log(user.token);
        let data = {
          idPurchase: data_row.original.Id,
          id_user: data_row.original.id_user,
          status: 2,
        };
        ApiService.postStatusDelivery(user.token, data).then(
          (res) => {
            if (res && res.status == 'success') {
              Swal.fire('Hecho!', 'Orden listo para entregar!', 'success');
              //toast.success("Orden Aceptada!");
              this.setState({ results: [] });
              this.getDataOrders();
            }
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    });
    //this.props.history.push("/edit-user/"+data_row.original.id);
    //window.location.reload();
  }

  handlerDelivered(data_row) {
    Swal.fire({
      title: 'Confirmar',
      text: '¿La orden nro ' + data_row.original.Id + ' ha sido entregado?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        var user = AuthService.getCurrentUser();
        let data = {
          idPurchase: data_row.original.Id,
          id_user: data_row.original.id_user,
          status: 3,
        };
        ApiService.postStatusDelivery(user.token, data).then(
          (res) => {
            if (res && res.status == 'success') {
              Swal.fire('Hecho!', 'Orden entregado!', 'success');
              this.setState({ results: [] });
              this.getDataOrders();
            }
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    });
    //this.props.history.push("/edit-user/"+data_row.original.id);
    //window.location.reload();
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentWillReceiveProps() {
    this.getDataOrders();
  }

  render() {
    const { open } = this.state;
    return (
      <Fragment>
        <Breadcrumb title="Pedidos recibidos" parent="Sales" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              
              <div className="card">
                <div className="card-body">{this.renderResults()}</div>
              </div>

            </div>
          </div>
        </div>

        <Modal open={open} onClose={this.onCloseModal}>
          <div className="modal-header">
            <h5 className="modal-title f-w-600" id="exampleModalLabel2">
              Orden n° {this.state.id_order}
            </h5>
          </div>
          <div className="modal-body">
            <div style={{ display: 'flex' }}>
              <label style={{ marginRight: '0.5rem', fontWeight: 'bold' }}>
                Usuario:
              </label>
              {this.state.client}
            </div>
            <div style={{ display: 'flex' }}>
              <label style={{ marginRight: '0.5rem', fontWeight: 'bold' }}>
                Distrito:
              </label>
              {this.state.district}
            </div>
            <div style={{ display: 'flex' }}>
              <label style={{ marginRight: '0.5rem', fontWeight: 'bold' }}>
                Dirección:
              </label>
              {this.state.address}
            </div>
            <div style={{ display: 'flex' }}>
              <label style={{ marginRight: '0.5rem', fontWeight: 'bold' }}>
                Interior:
              </label>
              {this.state.interior}
            </div>
            <div style={{ display: 'flex' }}>
              <label style={{ marginRight: '0.5rem', fontWeight: 'bold' }}>
                Url Google:
              </label>
              <a href={this.state.url_google} target="blank"
               style={{
                wordBreak: 'break-word', 
                overflowWrap: 'break-word', 
                textDecoration: 'none', 
                color: 'blue',
                fontSize: '1rem',
                maxWidth: '100%',
                display: 'inline-block'
              }}>
                {this.state.url_google}
              </a>
            </div>
            <div style={{ display: 'flex' }}>
              <label style={{ marginRight: '0.5rem', fontWeight: 'bold' }}>
                Fecha:
              </label>
              {this.state.date}
            </div>
            <div style={{ display: 'flex' }}>
              <label style={{ marginRight: '0.5rem', fontWeight: 'bold' }}>
                Hora:
              </label>
              {this.state.hour}
            </div>
            {this.state.cash > 0 ? (
              <div style={{ display: 'flex' }}>
                <label style={{ marginRight: '0.5rem', fontWeight: 'bold' }}>
                  Crédito:
                </label>
                {this.state.cash}
              </div>
            ) : (
              ''
            )}
            <div style={{ display: 'flex' }}>
              <label style={{ marginRight: '0.5rem', fontWeight: 'bold' }}>
                Delivery:
              </label>
              {this.state.delivery}
            </div>
            <div style={{ display: 'flex' }}>
              <label style={{ marginRight: '0.5rem', fontWeight: 'bold' }}>
                Total:
              </label>
              {this.state.total_order}
            </div>
            <div>
              <label style={{ fontWeight: 'bold' }}>Detalle:</label>
              {this.renderDetailResults()}
            </div>
          </div>
          <div className="modal-footer"></div>
        </Modal>
      </Fragment>
    );
  }
}

export default VerOrderDeliveryList;
