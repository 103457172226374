import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/sales-transactions';
import Datatable from '../common/datatable';
import VerChangeUserDatatable from '../common/ver-change-user-datatable';
import VerDetailsOrderDatatable from '../common/ver-details-order-datatable';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Modal from 'react-responsive-modal';
import Moment from 'moment';

export class VerListUserChange extends Component {
    constructor(props) {
        super(props);
        this.handlerAcept = this.handlerAcept.bind(this);
        this.handlerCancel = this.handlerCancel.bind(this);
        this.handlerDetails = this.handlerDetails.bind(this);
        this.state = {
            open: false,
            dataCateg: [],
            results: [],
            detailResults: [],
            id_user: '',
            id_ambassador: '',
            name: '',
            last_name: '',
            email: '',
            phone: '',
            type_doc: '',
            nro_doc: '',
            new_name: '',
            new_last_name: '',
            new_email: '',
            new_phone: '',
            new_type_doc: '',
            new_nro_doc: '',
        };
    }

    componentWillMount() {
        this.getDataChangeRequest();
    }

    getDataChangeRequest(){
        var user = AuthService.getCurrentUser();
        ApiService.getUserChangeRequest(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    console.log('orders',res.data)
                    const items = res.data.map(function(row) {
                        return { 
                            Nro: row.id, 
                            id_user: row.id_user,
                            Usuario : row.User?row.User.name+' '+ row.User.last_name:'', 
                            status : row.status,
                            Embajador : row.Ambassador?row.Ambassador.name +' '+ row.Ambassador.last_name:'',
                            Fecha: Moment(row.created_at).format('DD-MM-YYYY'),
                            data: row,
                        }
                     })
                this.setState({ results:items });
                this.renderResults();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    renderResults(){
		return this.state.results.length === 0 
			? ''
			: <VerChangeUserDatatable
            multiSelectOption={false}
            myData={this.state.results}
            pageSize={this.state.results.length <= 50? this.state.results.length:50}
            pagination={true}
            class="-striped -highlight"
            handlerAcept = {this.handlerAcept}
            handlerCancel = {this.handlerCancel}
            handlerDetails = {this.handlerDetails}
        />
    }   

    handlerDetails(data_row) {
        console.log('data_row',data_row);
        this.setState({ name: data_row.original.data.User.name });
        this.setState({ last_name: data_row.original.data.User.last_name });
        this.setState({ email: data_row.original.data.User.email });
        this.setState({ type_doc: data_row.original.data.User.type_document });
        this.setState({ phone: data_row.original.data.User.Profile.cell_phonenumber });
        this.setState({ nro_doc: data_row.original.data.User.nro_document });
        this.setState({ new_name: data_row.original.data.name });
        this.setState({ new_last_name: data_row.original.data.last_name });
        this.setState({ new_email: data_row.original.data.email });
        this.setState({ new_type_doc: data_row.original.data.type_document });
        this.setState({ new_phone: data_row.original.data.cell_phonenumber });
        this.setState({ new_nro_doc: data_row.original.data.nro_document });
        this.onOpenModal();
    }

    handlerAcept(data_row) {
        Swal.fire({
            title: 'Confirmar',
            text: '¿Desea aceptar los cambios?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
         }).then((result) => {
            if(result.value){
                
                var user = AuthService.getCurrentUser();
                let data = {
                    'id': data_row.original.data.id,
                    'id_user': data_row.original.data.id_user,
                    'status': 'APROBADO',
                    'id_ambassador': data_row.original.data.id_ambassador,
                }
                ApiService.putChangeUserRequest(user.token,data).then(
                    (res) => {
                        if(res && res.status == 'success'){
                            Swal.fire("Hecho!", "Cambios realizados!", "success");
                            //toast.success("Orden Aceptada!");
                            this.setState({results: []});
                            this.getDataChangeRequest();
                        }
                    },
                    error => {
                      const resMessage =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                    }
                  );




           }
         })
        //this.props.history.push("/edit-user/"+data_row.original.id);
        //window.location.reload();
    }

    handlerCancel(data_row) {
        Swal.fire({
            title: 'Confirmar',
            text: '¿Desea descartar los cambios?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
         }).then((result) => {
            if(result.value){
                
                var user = AuthService.getCurrentUser();
                let data = {
                    'id': data_row.original.data.id,
                    'id_user': data_row.original.data.id_user,
                    'status': 'RECHAZADO',
                    'id_ambassador': data_row.original.data.id_ambassador,
                }
                ApiService.putChangeUserRequest(user.token,data).then(
                    (res) => {
                        if(res && res.status == 'success'){
                            Swal.fire("Hecho!", "Cambios descartados!", "success");
                            this.setState({results: []});
                            this.getDataChangeRequest();
                        }
                    },
                    error => {
                      const resMessage =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                    }
                  );




           }
         })
        //this.props.history.push("/edit-user/"+data_row.original.id);
        //window.location.reload();
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentWillReceiveProps(){
        this.getDataChangeRequest()
    }

    render() {
        const { open } = this.state;
        return (
            <Fragment>
                <Breadcrumb title="Solicitud de cambios" parent="Cambios" />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Lista de Usuarios</h5>
                                </div>
                                <div className="card-body">
                                    <div id="batchDelete" className="transactions">
                                        {this.renderResults()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal open={open} onClose={this.onCloseModal} >

                <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">Usuario: {this.state.id_ambassador}</h5>
                </div>
                <div className="modal-body">
                    {
                    <div className="row">
                        <div className="col-md-12" style={{width: '30rem'}}>
                            <label style={{width: '100%',textAlign: 'center',fontWeight: 'bold'}}>Datos Actuales:</label>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Nombre:</label>
                                {this.state.name}
                            </div>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Apellido:</label>
                                {this.state.last_name}
                            </div>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Mail:</label>
                                {this.state.email}
                            </div>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Celular:</label>
                                {this.state.phone}
                            </div>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Tipo de Documento:</label>
                                {this.state.type_doc}
                            </div>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Nro. Documento:</label>
                                {this.state.nro_doc}
                            </div>
                        </div>                        
                        <div className="col-md-12">
                            <label style={{width: '100%',textAlign: 'center',fontWeight: 'bold'}}>Datos por Actualizar:</label>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Nombre:</label>
                                {this.state.new_name}
                            </div>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Apellido:</label>
                                {this.state.new_last_name}
                            </div>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Mail:</label>
                                {this.state.new_email}
                            </div>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Celular:</label>
                                {this.state.new_phone}
                            </div>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Tipo de Documento:</label>
                                {this.state.new_type_doc}
                            </div>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Nro. Documento:</label>
                                {this.state.new_nro_doc}
                            </div>
                        </div>
                    </div>
    }
                </div>
                <div className="modal-footer">
                </div>
                </Modal>
            </Fragment>
        )
    }
}

export default VerListUserChange
